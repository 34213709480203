@import "styles/scss/variable.scss";
.cmsTableWrapper {
  overflow-y: auto;
  .tableActionGroup {
    display: flex;
    .actionButton {
      border-radius: toRem(100);
      width: toRem(36);
      height: toRem(36);
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer !important;
      svg {
        width: 100%;
        height: 100%;
      }
      .approveIcon {
        width: toRem(20);
        height: toRem(20);
      }
      &:hover {
        background-color: #ccc;
      }
    }
    :global(.hideAction) {
      display: none;
    }
  }
  tbody {
    padding: 0 toRem(16);
    td {
      border-bottom: toRem(1) solid $BorderColor;
      height: 36px;
      padding: 16px 16px !important;
    }
  }
  :global(.MuiTableCell-root) {
    font-size: toRem(14);
    color: $PrimaryText;
    padding: toRem(12);
    @media screen and (min-width: 1200px) and (max-width: $DesktopMediumMaxSize) {
      font-size: 14px;
      line-height: 16px;
    }
    span,
    div {
      font-size: toRem(14);
      @media screen and (min-width: $DesktopMediumMinSize) and (max-width: $DesktopMediumMaxSize) {
        font-size: 14px;
      }
    }
  }
  :global(.cmsTable_keyCell) {
    color: #f5490f;
    cursor: pointer;
  }
  :global(.cmsTable_statusCell) {
    min-width: toRem(160);
  }
  :global(.cmsTable_subjectCellContact) {
    width: toRem(220);
  }
  :global(.cmsTable_messageCellContact) {
    width: toRem(280);
  }
  :global(.cmsTable_nameCellContact) {
    width: toRem(100);
  }
  :global(.cmsTable_statusCellContact) {
    min-width: toRem(90);
    width: toRem(90);
  }

  :global(.cmsTable_subjectCell) {
    min-width: toRem(180);
  }
  :global(.cmsTable_nameCell) {
    min-width: toRem(251);
  }
  :global(.iconCell) {
    div {
      width: toRem(44);
      height: toRem(44);
      svg {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  :global(.productShopImageCell) {
    img,
    svg {
      width: toRem(64);
      height: toRem(64);
      border-radius: toRem(12);
    }
  }
  :global(.cellCenterText) {
    text-align: center;
  }
  .pagination-show {
    margin-top: toRem(16);
    padding: 0 toRem(16);
    .showingPagingDescItem {
      text-align: right;
      .text {
        font-size: toRem(14);
        color: $SecondaryText;
      }
    }
  }
}
