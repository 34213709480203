@import 'styles/scss/variable.scss';
.all-content-version {
    .content-version-list {
        padding: 30px;
        .version-item-row {
          padding: 20px;
          box-sizing: border-box;
          .version-item {
            padding: 20px;
            display: flex;
            align-items: center;

            :global(.MuiOutlinedInput-input) {
              background-color: white;
            }
          
            :global(.CollapseCard) {
              margin-bottom: toRem(32);
              :global(.header) {
                background-color: $GrayColor4;
              }
              :global(.childrenContent) {
                background-color: $GrayColor4;
                padding: toRem(16);
              }
            }
            .remove-content {
              cursor: pointer;
              display: inline-block;
            }
            .add-content {
              cursor: pointer;
              display: inline-block;
              svg {
                color:$SuccessColor;
              }
              margin-left: 10px;
            }
          }
        }
    }
}